
import { IProductsCategoriesResponse } from '@/interfaces/products';
import { dispatchGetProductsList, dispatchGetProductsSubCategoriesList } from '@/store/products/actions';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CategorySubscription from '@/components/Products/CategorySubscription.vue';

@Component({
  components: {
    CategorySubscription,
  },
})

export default class CategoriesList extends Vue {
  public categoriesResponse: IProductsCategoriesResponse = {
    categories: [],
    parent: null,
  };
  public loadingState: any = {
    page: true,
  };

  @Watch('$route.params')
  public onParamsChanged(toParams, previousParams) {
    console.log('params changed', toParams, previousParams);
    this.loadPageData();
  }

  public mounted() {
    this.loadPageData();
  }

  public async loadPageData() {
    this.loadingState.page = true;
    this.categoriesResponse = await dispatchGetProductsSubCategoriesList(
      this.$store, {category: this.$router.currentRoute.params.codename ? this.$router.currentRoute.params.codename : null},
    );
    if (this.categoriesResponse.categories.length === 0) {
      if (this.$router.currentRoute.params.codename) {
        this.$router.push(`/catalog/${this.$router.currentRoute.params.codename}`);
      } else {
        this.$router.push('/home');
      }
    }
    this.loadingState.page = false;
  }

  public handleChangeCategory(category) {
    if (category.has_children) {
      this.$router.push(`/category/list/${category.codename}`);
    } else {
      this.$router.push(`/catalog/${category.codename}`);
    }
  }

}
